import BaseAPI from './base';

class OrganizationAccountTypeAPI extends BaseAPI {
  async getOrganizationAccountTypes() {
    return this.jsonApi.findAll('organization_account_types', {
      include: ['resource_type'],
    });
  }
}

export default OrganizationAccountTypeAPI;
